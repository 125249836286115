.footer {
  padding-top: 64px;
  background-color: #ffffff;
}

.footerPagesContainer {
  padding-top: 4rem;
}

.redirectionLink {
  color: #005EB8;
}

.ListLeftPadding {
  padding-inline-start: 1.12em;
}

.paraTopSpacing {
  margin-top: 32px;
}

.headingParaSpacing {
  margin-top: 12px;
}

.paraSpacing {
  margin-top: 20px;
}

.mainHeadingSpacing {
  margin-top: 20px;
}

@media screen and (min-width: 810px) and (max-width: 1279px) {
  .mainHeadingSpacing {
    margin-top: 30px;
  }
}

@media screen and (max-width: 810px) {
  .mainHeadingSpacing {
    margin-top: 32px;
  }
}