.contentAlign {
  margin: 0px 64px 64px 64px;
  max-width: 950px;
}
.loggedInSiteInfo {
  padding-top: 5px;
}
.siteInfo {
  padding-top: 5px;
}
.siteInfoTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  color: var(--weir-header-dark-text);
  text-decoration: none solid rgb(45, 52, 61);
  line-height: 40px;
  margin-top: 33px;
  padding-bottom: 15px;
}
